button {
  background: #FFFFFF;
  border: solid 2px #1a1a1a;
  padding: .375em 1.125em;
  font-size: 1rem;
  color: #333333;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

button:hover,
button:focus {
  background-color: #f0f0f0;
  color: #1a1a1a;
}

.button-arounder {
  font-size: 2rem;
  background: #F4F4F4;
  color: #333333;
  box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
  transform: translateY(0);
  border-radius: 0;
  --dur: .15s;
  --delay: .15s;
  --radius: 16px;

  transition:
    border-top-left-radius var(--dur) var(--delay) ease-out,
    border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
    border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
    border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
    box-shadow calc(var(--dur) * 4) ease-out,
    transform calc(var(--dur) * 4) ease-out,
    background calc(var(--dur) * 4) steps(4, jump-end);
}

.button-arounder:hover,
.button-arounder:focus {
  box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, .2);
  transform: translateY(-4px);
  background: #E0E0E0;
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}


