.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .copy-button,
  .modal-close {
    position: absolute;
    top: 8px;
    background: none;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    color: #555;
    transition: color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  
  .modal-close {
    right: 16px;
  }
  
  .copy-button {
    right: 56px;
  }
  
  .copy-button svg,
  .modal-close svg {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .copy-button:hover,
  .modal-close:hover {
    color: #333;
  }
  
  pre {
    font-size: 12px;
    margin: 0;
  }
  
  .copy-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: green;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid green;
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 4px 8px;
  }
  