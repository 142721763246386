/* CBPay.css */

.cbPayIFrameContainer {
  display: flex;
  flex-wrap: nowrap;
  background-color: #FFFFFF;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto;
}

.leftContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  border-right: 1px solid #e0e0e0;
}

.embeddedContent {
  flex: 1;
  position: relative;
  height: 800px;
  border: none;
  overflow: hidden;
  border-radius: 4px;
  background-color: #f7f8fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.label {
  flex: 1;
  margin-right: 16px;
  font-weight: bold;
}

.select {
  flex: 2;
  font-size: 16px;
  font-family: Arial, sans-serif;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease-in-out;
}

.select:focus {
  outline: none;
  border-color: #3f88c5;
  box-shadow: 0 0 0 2px rgba(63, 136, 197, 0.2);
}
