/* BuyWithCoinbaseButton.module.css */
.buttonContainer {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
  }
  
  .buttonContainer:hover img,
  .buttonContainer:focus img {
    opacity: 1.0;
  }
  